import LazyLoad from "react-lazyload"
import PropTypes from "prop-types"

function ImageUrl(props) {
  const { classes, images, widths, sizes, alt, placeholderHeight, lazy } = props
  let urlImages = ""
  let urlSizes = ""

  images.map((value, index) => (urlImages += `${process.env.NEXT_PUBLIC_CLOUDINARY}${value} ${widths[index]}`))
  sizes.map(value => (urlSizes += value))

  const El = <img alt={alt} className={classes} srcSet={urlImages} sizes={urlSizes} />

  if (!lazy) return El

  return <LazyLoad height={placeholderHeight}>{El}</LazyLoad>
}

ImageUrl.defaultProps = {
  placeholderHeight: 200,
  lazy: true
}

ImageUrl.propTypes = {
  classes: PropTypes.array,
  images: PropTypes.array,
  widths: PropTypes.array,
  sizes: PropTypes.array,
  alt: PropTypes.string,
  placeholderHeight: PropTypes.number,
  lazy: PropTypes.bool
}

export default ImageUrl
