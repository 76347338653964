import dynamic from "next/dynamic"
import LazyLoad from "react-lazyload"
import SEOData from "components/SEOData"
import Header from "./Header"
import DefaultLayout from "components/layouts/DefaultLayout"

const Testimonials = dynamic(() => import("./Testimonials"), { ssr: false })
const Conditions = dynamic(() => import("./Conditions"), { ssr: false })
const Offers = dynamic(() => import("./Offers"), { ssr: false })

export default function HomeScene() {
  return (
    <DefaultLayout topBarProps={{ pageName: "homepage" }} footerProps={{ pageName: "homepage" }}>
      <SEOData
        title="Renting de carros - As melhores campanhas"
        description="Estreia carro de renting com tudo incluído e sem entrada. Ofertas para particulares e profissionais com a garantia dos principais distribuidores."
      />
      <Header />
      <LazyLoad height={500} once>
        <Offers />
        <Conditions />
        <Testimonials />
      </LazyLoad>
    </DefaultLayout>
  )
}
