import { createContext } from "react"
import * as PropTypes from "prop-types"

export const HomeContext = createContext({})

const HomeContextProvider = props => {
  const { children, cars } = props

  return <HomeContext.Provider value={{ cars }}>{children}</HomeContext.Provider>
}

HomeContextProvider.propTypes = {
  cars: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired
}

export default HomeContextProvider
