import { Col, Container, Row } from "reactstrap"
import styled from "styled-components"
import { useRouter } from "next/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PrimaryButton } from "../../../components/styled/Buttons"
import ImageUrl from "../../../components/ImageUrl"
import { sendEvent } from "../../../scripts/GoogleAnalytics"
import Typography from "../../../components/styled/Typography"

const Wrapper = styled.div`
  color: #fff;
  margin-top: -12rem;
  overflow: hidden;
`

const Background = styled.div`
  padding-top: 12rem;
  padding-bottom: 225px;
  z-index: 1;
  color: white;
  position: relative;
  overflow: hidden;
  background-image: url("${process.env.NEXT_PUBLIC_CLOUDINARY}w_575,f_auto,q_auto/micochederenting/home-header");
  @media (min-width: 576px) {
    background-image: url("${process.env.NEXT_PUBLIC_CLOUDINARY}w_767,f_auto,q_auto/micochederenting/home-header");
    padding-bottom: 275px;
  }
  @media (min-width: 768px) {
    background-image: url("${process.env.NEXT_PUBLIC_CLOUDINARY}w_991,f_auto,q_auto/micochederenting/home-header");
  }
  @media (min-width: 992px) {
    background-image: url("${process.env.NEXT_PUBLIC_CLOUDINARY}w_1199,f_auto,q_auto/micochederenting/home-header");
  }
  @media (min-width: 1200px) {
    background-image: url("${process.env.NEXT_PUBLIC_CLOUDINARY}w_2000,f_auto,q_auto/micochederenting/home-header");
  }
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    object-fit: contain;
    mix-blend-mode: multiply;
    z-index: 1;
    box-shadow: 0 24px 59px 0 rgba(51, 59, 69, 0.15);
    background: url("${process.env.NEXT_PUBLIC_CLOUDINARY_AUTO}cdr-gradient"),
      linear-gradient(234deg, ${({ theme }) => theme.colors.softGreen} 94%, ${({ theme }) => theme.colors.dark} -6%);
    background-size: cover;
  }
`

const Title = styled(Typography)`
  && {
    color: white;
    font-weight: 300;
    margin: 0 auto;
  }
`

const HighlightedText = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  margin-left: 10px;
`

const Content = styled(Container)`
  && {
    overflow: visible;
  }
  position: relative;
  z-index: 10;
  text-align: center;
  max-width: 600px;
  @media (min-width: 1200px) {
    max-width: 100%;
  }
`

const Button = styled(PrimaryButton)`
  color: white;
  height: 60px;
  border-radius: 8px;
  box-shadow: 0 4px 24px 0 rgba(238, 76, 20, 0.8);
  border: none;
  background-image: linear-gradient(
    103deg,
    ${({ theme }) => theme.colors.softOrange} -30%,
    ${({ theme }) => theme.colors.primary} 70%
  );
  margin: 25px auto;
  h5 {
    margin: 0;
  }
  &:hover {
    box-shadow: none;
    color: white;
    background: ${({ theme }) => theme.colors.softOrange};
  }
`

const Description = styled.div`
  margin-top: -50px;
  z-index: 2;
  position: relative;
  padding: 50px 0;
  text-align: center;
  border-radius: 25px 25px 0 0;
  background: ${({ theme }) => theme.colors.dark};
  h3 {
    margin: 0 auto;
    max-width: 600px;
    @media (min-width: 1200px) {
      max-width: 100%;
    }
  }
`

const HeaderImage = styled.div`
  margin-top: -175px;
  @media (min-width: 576px) {
    margin-top: -225px;
  }
`

export default function Header() {
  const router = useRouter()

  const onClick = () => {
    sendEvent("Click CTA", { event_category: "homepage", event_label: "main header CTA" })
    return router.push("/multistep")
  }

  return (
    <Wrapper>
      <Background>
        <Content>
          <Title variant="h2">
            O seu carro de renting
            <HighlightedText>com tudo incluído</HighlightedText>
          </Title>
          <Button width={275} onClick={onClick}>
            <Typography variant="h5" className="d-inline-block">
              Ver ofertas
            </Typography>
            <FontAwesomeIcon icon={["fam", "car"]} size="2x" className="ml-3" />
          </Button>
        </Content>
      </Background>
      <Description>
        <HeaderImage>
          <ImageUrl
            alt="Header car"
            images={[
              "w_600,f_auto,q_auto/micochederenting/mcdr-cars",
              "w_700,f_auto,q_auto/micochederenting/mcdr-cars"
            ]}
            widths={["600w,", "700w"]}
            sizes={["600px,", "(min-width: 992px) 700px"]}
            placeholderHeight={250}
          />
        </HeaderImage>
        <Container>
          <Row>
            <Col xs={12} lg={4}>
              <ImageUrl
                alt="Number 1"
                images={["w_68,f_auto,q_auto/micochederenting/number-1"]}
                widths={["68w"]}
                sizes={["68px"]}
                classes={["mb-3"]}
                placeholderHeight={68}
              />
              <Typography variant="h3" color="primary" className="py-2">
                À sua medida
              </Typography>
              <Typography variat="body">Escolhe um carro adaptado às suas necessidades</Typography>
            </Col>
            <Col xs={12} lg={4}>
              <ImageUrl
                alt="Number 2"
                images={["w_68,f_auto,q_auto/micochederenting/number-2"]}
                widths={["68w"]}
                sizes={["68px"]}
                classes={["mb-3"]}
                placeholderHeight={68}
              />
              <Typography variant="h3" color="primary" className="py-2">
                100% online
              </Typography>
              <Typography variat="body">Contrata desde casa sem papeladas</Typography>
            </Col>
            <Col xs={12} lg={4}>
              <ImageUrl
                alt="Number 3"
                images={["w_68,f_auto,q_auto/micochederenting/number-3"]}
                widths={["68w"]}
                sizes={["68px"]}
                classes={["mb-3"]}
                placeholderHeight={68}
              />
              <Typography variant="h3" color="primary" className="py-2">
                Sem imprevistos
              </Typography>
              <Typography variat="body">
                Disfrute do seu carro de renting numa prestação mensal e sem entrada
              </Typography>
            </Col>
          </Row>
        </Container>
      </Description>
    </Wrapper>
  )
}
