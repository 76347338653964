import * as PropTypes from "prop-types"
import HomeScene from "scenes/HomeScene"
import { getHomeData } from "services/webService"
import HomeContextProvider from "scenes/HomeScene/HomeContext"

export default function Home(props) {
  const { cars } = props

  return (
    <HomeContextProvider cars={cars}>
      <HomeScene />
    </HomeContextProvider>
  )
}

Home.defaultProps = {
  cars: []
}

Home.propTypes = {
  cars: PropTypes.array
}

export async function getStaticProps() {
  const res = await getHomeData("pt")
  const cars = await res.json()

  return {
    props: { cars },
    revalidate: 60
  }
}
